.App {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

}



.virtual-section {
  height: 100vh;
  width: 100%;
  position: relative;
}

/* Styles pour le smooth scroll */
html {
  overflow: hidden;
  height: 1000%;
}

body {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
}

canvas {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed;
  top: 0;
  left: 0;
}

/* Pour désactiver le scroll sur mobile */
@media (max-width: 768px) {
  .virtual-scroll {
    touch-action: none;
  }
}


/* App.css */
.carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  background: #000;
}

.carousel-container.visible {
  opacity: 1;
  transform: scale(1);
}
.cursor {
  width: 8px;
  height: 8px;
  background: black;
  border-radius: 100%;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  transition: transform 0.2s;
}

.cursor.active {
  transform: scale(2);
  background: #ff3366;
}

.cursor-follower {
  width: 32px;
  height: 32px;
  border: 2px solid black;
  border-radius: 100%;
  position: fixed;
  z-index: 9998;
  pointer-events: none;
  transition: transform 0.2s, border-color 0.2s;
}

.cursor-follower.active {
  transform: scale(1.5);
  border-color: #ff3366;
  background: rgba(255, 51, 102, 0.1);
}

/* Cacher le curseur par défaut */
* {
  cursor: none !important;
}